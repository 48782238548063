export const componentOptions = {
  SpecialBuyOfTheDay: {
    sizes: {
      minWidthMedium: {
        min: 6,
        max: 12,
      },
      minWidthLarge: {
        min: 4,
        max: 12,
      },
    },
  },
  'Recently Viewed': {
    sizes: {
      minWidthMedium: {
        min: 6,
        max: 12,
      },
      minWidthLarge: {
        min: 8,
        max: 12,
      },
    },
  },
  'Top Savings for You': {
    sizes: {
      minWidthMedium: {
        min: 12,
        max: 12,
      },
      minWidthLarge: {
        min: 12,
        max: 12,
      },
    },
  },
  'Todays Recommendations for You': {
    sizes: {
      minWidthMedium: {
        min: 6,
        max: 12,
      },
      minWidthLarge: {
        min: 4,
        max: 12,
      },
    },
  },
};
